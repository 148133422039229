import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="iframe"
export default class extends Controller {
  connect() {
    this.element.addEventListener("load", this.iframeLoaded.bind(this));
    this.element.addEventListener("load", this.makeLinksExternal.bind(this));
    this.element.addEventListener("load", this.removeInlineImages.bind(this));
    document.addEventListener("DOMContentLoaded", this.removeInlineImages());
  }

  iframeLoaded() {
    this.element.height =
      this.element.contentWindow.document.body.scrollHeight + 50 + "px";
  }

  makeLinksExternal() {
    const links = this.element.contentWindow.document.querySelectorAll("a");
    links.forEach((link) => {
      link.setAttribute("target", "_blank");
    });
  }

  removeInlineImages() {
    const images = document.querySelectorAll(".proton-embedded");
    images.forEach((image) => {
      image.remove();
    });
  }
}
